
import Vue from "vue";

export default Vue.extend({
  name: "ContactUsPage",
  data() {
    return {
      data: {
        email: "",
        title: "",
        message: ""
      },
      errors: {},
      btnLoading: false
    };
  },
  methods: {
    onSubmit() {
      this.btnLoading = true;

      this.$store
        .dispatch("helper/POST_CONTACT_FORM", this.data)
        .then(message => {
          this.btnLoading = false;
          // @ts-ignore
          this.$alertify.success(message);
          this.errors = {};
          this.data = {
            email: "",
            title: "",
            message: ""
          };
        })
        .catch(error => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    hasError(type: string) {
      //@ts-ignore
      if (type in this.errors && this.errors[type][0].length > 0) {
        //@ts-ignore
        return { error: this.errors[type][0] };
      }
      return null;
    }
  }
});
